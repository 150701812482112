import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
    {
      sousId : 9999,
      tokenName : 'CAKE',
      stakingTokenName : QuoteToken.CAKE,
      stakingTokenAddress : '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      contractAddress : {
        97 : '',
        56 : '0xc6670431b525b820ea7941cd3849dba29e729834' // done
      },
      poolCategory : PoolCategory.CORE,
      projectLink : 'https://pancakeswap.finance/swap/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      harvest : true,
      tokenPerBlock : '0',
      sortOrder : 1,
      isFinished : true,
      tokenDecimals : 18,
      nativeFarmId: 15,
      multiplier:0,
    },
   {
     sousId : 99991,
     tokenName : 'USDT-BUSD',
     stakingTokenName : QuoteToken.BUSDUSDT,
     stakingTokenAddress : '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
     contractAddress : {
       97 : '',
       56 : '0x9bde93cC9b19Fd83D1a731AAd6EE7d8dC995D2Ba' // done
     },
     poolCategory : PoolCategory.CORE,
     projectLink : 'https://pancakeswap.finance',
     harvest : true,
     tokenPerBlock : '500', // multilier
     sortOrder : 2,
     isFinished : false,
     tokenDecimals : 18,
     isLp:true,
     pancakePoolId:258,
     nativeFarmId:6,
     multiplier:5,
   }
   ,
   {
     sousId : 99992,
     tokenName : 'CAKE-BNB',
     stakingTokenName : QuoteToken.CAKEBNB,
     stakingTokenAddress : '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
     contractAddress : {
       97 : '',
       56 : '0x82dd39882443547F72b6597124451e9d9141643F' // done
     },
     poolCategory : PoolCategory.CORE,
     projectLink : 'https://pancakeswap.finance',
     harvest : true,
     tokenPerBlock : '500', // multilier
     sortOrder : 3,
     isFinished : false,
     tokenDecimals : 18,
     isLp:true,
     pancakePoolId:251,
     nativeFarmId:5,
     multiplier:5,
   }
   ,
   {
     sousId : 99993,
     tokenName : 'BUSD-BNB',
     stakingTokenName : QuoteToken.BUSDBNB,
     stakingTokenAddress : '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
     contractAddress : {
       97 : '',
       56 : '0x157E166D8C8f2EDcfD6256e7949bA5e21a6f9065' // done
     },
     poolCategory : PoolCategory.CORE,
     projectLink : 'https://pancakeswap.finance',
     harvest : true,
     tokenPerBlock : '500', // multilier
     sortOrder : 4,
     isFinished : false,
     tokenDecimals : 18,
     isLp:true,
     pancakePoolId:252,
     nativeFarmId:0,
     multiplier:5,
   }
   ,
    {
      sousId : 99994,
      tokenName : 'CAKE-USDT',
      stakingTokenName : QuoteToken.CAKEUSDT,
      stakingTokenAddress : '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
      contractAddress : {
        97 : '',
        56 : '0xb7CCd0E859Bef98D8cE44Fe95E37621305f6bA96' // done
      },
      poolCategory : PoolCategory.CORE,
      projectLink : 'https://pancakeswap.finance',
      harvest : true,
      tokenPerBlock : '500', // multilier
      sortOrder : 5,
      isFinished : false,
      tokenDecimals : 18,
      isLp:true,
      pancakePoolId:422,
      nativeFarmId:33,
      multiplier:5,
    }
    ,
     {
     sousId : 99995,
     tokenName : 'CAKE-BUSD',
     stakingTokenName : QuoteToken.CAKEBUSD,
     stakingTokenAddress : '0x804678fa97d91B974ec2af3c843270886528a9E6',
     contractAddress : {
       97 : '',
       56 : '0xDEdd17240595BD501bB0e19Eb20719CB63aB442F' // done
     },
     poolCategory : PoolCategory.CORE,
     projectLink : 'https://pancakeswap.finance',
     harvest : true,
     tokenPerBlock : '500', // multilier
     sortOrder : 6,
     isFinished : false,
     tokenDecimals : 18,
     isLp:true,
     pancakePoolId:389,
     nativeFarmId:33,
     multiplier:5,
   }
  ,
  {
   sousId : 99996,
   tokenName : 'BTCB-BUSD',
   stakingTokenName : QuoteToken.BTCBBUSD,
   stakingTokenAddress : '0xf45cd219aef8618a92baa7ad848364a158a24f33',
   contractAddress : {
     97 : '',
     56 : '0x4A8f772ce22109b36ea759c97B0033874901EEAe' // done
   },
   poolCategory : PoolCategory.CORE,
   projectLink : 'https://pancakeswap.finance',
   harvest : true,
   tokenPerBlock : '400', // multilier
   sortOrder : 7,
   isFinished : false,
   tokenDecimals : 18,
   isLp:true,
   pancakePoolId:365,
   nativeFarmId:1,
   multiplier:4,
 }
 ,
  {
   sousId : 99997,
   tokenName : 'USDT-BNB',
   stakingTokenName : QuoteToken.USDTBNB,
   stakingTokenAddress : '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
   contractAddress : {
     97 : '',
     56 : '0xFbF3E721968F19d8746A3D0497f625f954C0096a' // done
   },
   poolCategory : PoolCategory.CORE,
   projectLink : 'https://pancakeswap.finance',
   harvest : true,
   tokenPerBlock : '500', // multilier
   sortOrder : 8,
   isFinished : false,
   tokenDecimals : 18,
   isLp:true,
   pancakePoolId:264,
   nativeFarmId:0,
   multiplier:5,
 }
,
{
 sousId : 99998,
 tokenName : 'BTCB-BNB',
 stakingTokenName : QuoteToken.BTCBBNB,
 stakingTokenAddress : '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
 contractAddress : {
   97 : '',
   56 : '0x9f6eeFeD81051D8476ecEA76fD60fa9DC03608c8' // done
 },
 poolCategory : PoolCategory.CORE,
 projectLink : 'https://pancakeswap.finance',
 harvest : true,
 tokenPerBlock : '400', // multilier
 sortOrder : 9,
 isFinished : false,
 tokenDecimals : 18,
 isLp:true,
 pancakePoolId:262,
 nativeFarmId:3,
 multiplier:4,
}
]

export default pools