import { MenuEntry } from '@macist-m/robinia-uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Swap',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xa05e01E9dF824CCAd284895Fff43B043e2133f50',
      },
     {
       label: 'Liquidty',
       href: 'https://pancakeswap.finance/add/BNB/0xa05e01E9dF824CCAd284895Fff43B043e2133f50',
     },
   ],
  },
   {
     label: 'Farms',
     icon: 'FarmIcon',
     items:[
            {
              label:"Farms",
              href: '/farms'
            },
            {
              label: 'Cake Farm',
              href: '/cakePools',
            },
     ]
   },
  // {
  //   label: 'Referrals',
  //   icon: 'HamburgerIcon',
  //   href: '/referrals',
  // },
  // {
  //   label: 'Trade',
  //   icon: 'TradeIcon',
  //   href: '/trade'
  // },
   {
     label: 'Pools',
     icon: 'PoolIcon',
     href: '/pools',
   },
   {
    label: 'Bridge',
    icon: 'NftIcon',
    href: 'https://bridge.robiniaswap.com/ ',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
       {
         label: 'Docs',
         href: 'https://docs.yanabu.com/',
       },
      {
        label: 'Audit',
        href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/November/Yanabu.pdf',
      },
      {
        label: 'Price Chart',
        href: 'https://swap.arken.finance/tokens/bsc/0xa05e01e9df824ccad284895fff43b043e2133f50',
      },
    ],
  },
  // {
  //   label: 'Jungles',
  //   icon: 'EggHouseIcon',
  //   href: '/jungles',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },

  // {
  //   label: 'IPO',
  //   icon: 'GooseIcon',
  //   href: '/ipo',
  // },
  // {
  //   label: 'Audits',
  //   icon: 'AuditIcon',
  //   href: '/audits',
  // },
  // {
  //   label: 'Features',
  //   icon: 'InfoIcon',
  //   href: '/features',
  // },
  // {
  //   label: 'Listing',
  //   icon: 'LayerIcon',
  //   href: '/listing',
  // },
  // {
  //   label: 'Analytics',
  //   icon: 'NftIcon',
  //   href: '/analytics',
  // },






  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/goosedefi/',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://goosedefi.gitbook.io/goose-finance/',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://goosefinance.medium.com/',
  //     },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
