import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@macist-m/robinia-uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background: linear-gradient(0deg, #313641, #313641);

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .rbs-bg {
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(45px);
  }
  .footer {
    border-radius:10px;
  }

  .topfarmv1{
    justify-content:center;
  }

  .rbs-card-top{
    padding: 25px;
    border-radius: 30px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.05);
    margin-left: 6px;
    margin-right: 6px;
  }
  .card-liq{
    margin-right:122px;
  }

  .rbs-card {
    background: rgba(37,43,56,255);
    backdrop-filter: blur(45px);
    padding: 25px;
    border-radius: 30px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.05);
    margin-left: 6px;
    margin-right: 6px;
    ${({ theme }) => theme.mediaQueries.sm} {
      margin: 0 auto;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      margin: 0 auto;
    }
  }

  .slick-list> div {
    margin-left:0;
  }
  .timeline-Tweet-text{
    color:#fff !important;
  }

`

export default GlobalStyle
