import React from 'react'
import { usePriceCakeBusd, useTotalValue } from 'state/hooks'
import { useTotalSupply} from 'hooks/useTokenBalance'
import { isImportDeclaration } from 'typescript'
import CardValue from 'views/Home/components/CardValue'
import { getBalanceNumber } from 'utils/formatBalance'
import Socials from './Socials'

declare global {
  interface Window {
      ethereum:any;
  }
}
const addToMetamask =  function (){
       window.ethereum
   .request({
     method: 'wallet_watchAsset',
     params: {
       type: 'ERC20',
       options: {
         address: '0xa05e01E9dF824CCAd284895Fff43B043e2133f50',
         symbol: 'YNB',
         decimals: 18,
         image: 'https://yanabu.com/images/favicons/apple-icon-72x72.png',
       },
     },
   })
   .then((success) => {
     if (success) {
       console.log('YNB successfully added to wallet!')
     } else {
       throw new Error('Something went wrong.')
     }
   })
   .catch(console.error)
}
const MainFooter = () => {
  const cakePriceUsd = usePriceCakeBusd()
  const totalValue = useTotalValue()
  const totalSupply = useTotalSupply()

  return (
    <div className=" bg-origin-border bg-yellow-300 bg-cover min-w-full footer ">
              <div style={{paddingLeft:"16px",paddingRight:"16px"}} className="  py-3">
          <div className=" grid md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
            {/* Price Block */}
            <div className="flex flex-row justify-center md:justify-center lg:justify-start">
              <div className="w-18 mr-2">
                <img src="/images/yanabu-ico.png" alt="rbs-ico" width="60px" />
              </div>
              <div className="flex flex-col items-start justify-center">
                <div className="text-red-600 mb-1">YNB Price</div>
                <div className="font-bold text-md">
                  ${cakePriceUsd.toNumber().toFixed(3)}
                </div>
              </div>
            </div>
            {/* Metamask Link */}
            <div className="flex flex-row justify-center items-center">
              <div className="mr-2">
                <img src="/images/MetaMask_Fox.png" alt="rbs-ico" width="35px" />
              </div>
              <button type="button" onClick={addToMetamask} className="flex flex-col items-start justify-center">
                <div className="font-bold text-md text-black-600">
                  Add YNB to Metamask
                </div>
              </button>
            </div>
            {/* BCN Pricing */}
            <div className="flex flex-row justify-center">
              <div className="flex flex-col items-start justify-center">
                <div className="flex w-52 justify-between">
                  <div className="text-md text-red-600">YNB Price</div>
                  <div className="text-md text-red-600">Total YNB</div>
                </div>
                <div className="flex w-52 justify-between">
                  <div className="font-bold text-md text-black-600">${cakePriceUsd.toNumber().toFixed(3)}</div>
                  <div className="font-bold text-md text-black-600">{getBalanceNumber(totalSupply).toFixed(2)}</div>
                </div>
              </div>
            </div>
            {/* TVL */}

            <div className=" flex flex-col items-center md:items-center lg:items-end">
              <div className="text-md text-red-600">
                Total Value Locked (TVL)
              </div>
              <div style={{color:"black !important"}}>
              {(totalValue.toNumber() > 0) ?
                    <CardValue
                    value={totalValue.toNumber()}
                    prefix="$"
                    decimals={2}
                    fontSize="18px"
                    color="#000"
                  />
                :
                <CardValue
                value={0}
                prefix="$"
                decimals={2}
                fontSize="18px"
                color="#000"
              />
              }
              </div>
            </div>
          </div>
        </div>
      </div>

  )
}

export default MainFooter
